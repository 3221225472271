
/*
	0: "kosher",
    1: "halal",
    2: "vegan",
    3: "vegetarian",
*/

const FilterColours = [

    '#4285f4',
    '#fbbc04',
    '#34a853',
    '#ea4335',
];

export default FilterColours;
