
const Filters = [

	"kosher",
    "halal",
    "vegan",
    "vegetarian",
]

export default Filters;
